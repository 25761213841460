<template>
  <div class="class">
    <div class="class-title">课程配置</div>
    <div class="class-content">
      <div class="class-content-left">
        <div class="class-left-head">
          <i><img src="../../../assets/img/school/Icon_bjlist.png" alt="" /></i
          >班级列表
        </div>
        <div class="class-left-list">
          <div class="menu-item-1">
            <div class="menu-item-title">
              <span>全部班级</span>
            </div>
            <div
              class="menu-item-1-1"
              v-for="(item, index) in menuData"
              :key="index"
            >
              <div
                class="menu-item-1-title"
                @click="bindMenu(index)"
                :class="isModel === index ? 'activeSan' : ''"
              >
                <span>{{ item.label }}</span>
              </div>
              <div class="menu-item-1-2" v-if="isModel === index">
                <div
                  class="menu-item-1-2-con"
                  v-for="(item, indexI) in item.menuList"
                  :key="indexI"
                  @click="getBanID(item.id, item.type)"
                  :class="gradeId === item.id ? 'active' : ''"
                >
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="class-content-right">
        <div class="class-right-head">
          <div class="class-right-int">
            <div class="class-right-int-left">
              <p>
                幼儿: <span>{{ studentCount }}人</span>
              </p>
              <p>
                已配课幼儿: <span>{{ planStudents }}人</span>
              </p>
            </div>
            <div class="class-right-int-right">
              <div
                class="class-right-head-input-btn"
                @click="bindChekPre()"
                v-if="!$store.state.userInfo.teacherId"
              >
                给该班级配课({{ peike }})
              </div>
              <div class="class-right-head-input-fen">
                <div class="input-fen-right" v-if="isHideSec">
                  <input
                    type="text"
                    placeholder="请输入要查询的内容"
                    v-model="seachVal"
                    @change="changSeachVal"
                  />
                  <input
                    style="display: block; width: 0px; height: 0px; opacity: 0"
                    placeholder=""
                    resize="none"
                  />
                </div>
                <span @click="bindSeach"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="class-stu">
          <div class="class-stu-chex">
            <h2>幼儿</h2>
            <div
              class="stu-chex-btn"
              @click="checkedAll()"
              v-if="isChekShow && stuData.length > 0"
            >
              <img src="../../../assets/img/school/Icon_alls.png" alt="" />
              全选
            </div>
          </div>
          <div class="class-sut-con" v-if="stuData.length > 0">
            <ul>
              <li
                v-for="(item, index) in stuData"
                :key="index"
                :class="
                  item.planStatus !== 0
                    ? 'stu-class-null-bg'
                    : $store.state.fruitIdsPk.indexOf(item.id) >= 0
                    ? 'activeBg'
                    : 'stu-class-bg'
                "
                @click="checkedOne(item.id)"
              >
                <input
                  type="checkbox"
                  v-if="item.planStatus == 0 && isChekShow"
                  :checked="$store.state.fruitIdsPk.indexOf(item.id) >= 0"
                  name="checkboxinput"
                  class="input-checkbox"
                />
                <div class="class-sut-con-item">
                  <!-- <p class="stu-name">{{ item.name }}</p>
                  <p class="stu-class">
                    {{ item.planStatus === 0 ? "未配课" : "已配课" }}
                  </p> -->
                  <div class="stu-photo">
                    <img
                      :src="constant.URL + item.photo"
                      alt=""
                      v-if="item.photo"
                    />
                  </div>
                  <div class="stu-info">
                    <p class="stu-name">{{ item.name }}</p>
                    <p class="stu-class">
                      {{ item.planStatus === 0 ? "未配课" : "已配课" }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="class-sut-con-null" v-else>
            <ul>
              <li class="stu-class-bg-null">
                <img src="../../../assets/img/school/Icon_Stud_Ks.png" alt="" />
                <p>暂无幼儿</p>
              </li>
            </ul>
          </div>
          <div class="page" v-if="stuData.length > 0">
            <comPagination
              :params="updateParams"
              @changePage="changeUpadtePage"
            ></comPagination>
          </div>
          <div class="class-stu-btn" v-if="isChekShow && stuData.length > 0">
            <el-button
              class="class-stu-bind"
              @click="handleConfigSubmit"
              :loading="isLoding"
            >
              确认
            </el-button>
            <div class="class-stu-cane" @click="bindCane">取消</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import comPagination from "@/components/admin/pagination.vue";
export default {
  data() {
    return {
      isLoding: false,
      isHideSec: false,
      seachVal: "",
      isModel: "",
      updateParams: {
        // 分页
        page: 0,
        size: 50,
        total: 0,
      },
      gradeId: "", // 班级ID
      menuData: "", // 班级列表
      isChekShow: false,
      show: "3",
      isShow: "7",
      fruitIds: [],
      peike: "", // 配课数量
      // 初始化全选按钮, 默认不选
      isCheckedAll: false,
      activeName: "",
      stuData: [],
      data: [], // 学校班级列表
      defaultProps: {
        children: "children",
        label: "label",
      },
      planStudents: 0,
      studentCount: 0,
      teacherCount: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    //初始化
    init() {
      console.log(1111111111);
      this.getfind(); // 获取配课数量
      this.getClassInfo(); // 获取班级列表
    },
    // 分页
    changeUpadtePage(val) {
      this.updateParams.page = val;
      this.getStuInfo(this.gradeId);
    },
    // V2.0获取默认学生.老师,已配课老师数量
    findGradeInfo(id) {
      let data = {
        gradeId: id,
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.school.findGradeInfo(data).then((res) => {
        this.planStudents = res.data.planStudents;
        this.studentCount = res.data.studentCount;
        this.teacherCount = res.data.teacherCount;
      });
    },
    // 获取学生信息
    getStuInfo(id) {
      let data = {
        gradeId: id,
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.school.findStusByGradeId(this.updateParams, data).then((res) => {
        console.log(res, "查询学生");
        this.stuData = res.data.list;
        this.updateParams.total = res.data.total;
      });
    },

    getBanID(id, type) {
      this.gradeId = id;
      this.getStuInfo(id);
      this.findGradeInfo(id);
      this.banType = type;
      this.$store.state.fruitIdsPk = [];
      this.isChekShow = false;
    },
    // v2,0 菜单
    bindMenu(index) {
      if (this.isModel === index) {
        this.isModel = "";
      } else {
        this.isModel = index;
      }
    },
    // V2.0 获取学校列表
    getClassInfo() {
      this.menuData = [];
      this.api.school
        .getSchoolGradeList(this.$store.state.userInfo.schoolId)
        .then((res) => {
          let person = { ...res.data };
          for (let val in person) {
            if (person[val].length && this.gradeId == "") {
              this.gradeId = person[val][0].id;
              this.getStuInfo(person[val][0].id);
              this.findGradeInfo(person[val][0].id);
            }

            let obj = {
              label: val,
              menuList: person[val],
            };
            this.menuData.push(obj);
          }
        });
    },
    // 非公益配课数量
    getfind() {
      let data = {
        schoolId: JSON.parse(localStorage.getItem("userInfo")).schoolId,
      };
      this.api.admin.findHasClass(data).then((res) => {
        console.log(res);
        this.peike = res.data;
      });
    },
    // 取消配课 显示多选框
    bindCane() {
      this.isChekShow = false;
      this.$store.state.fruitIdsPk = [];
    },
    bindChekPre(){
      this.$store.dispatch('checkAuth',{
        id:26,
        fn:this.bindChek,
        that:this,
      })
    },
    // 开始配课 显示多选框
    bindChek() {
      this.isChekShow = true;
    },
    // 确认配课按钮
    handleConfigSubmit() {
      this.isLoding = true;
      if (this.$store.state.fruitIdsPk.length != 0) {
        if (this.$store.state.fruitIdsPk.length > this.peike) {
          this.$notice({
            message: "选择人数大于可配课人数,请重新选择",
          }).isShow();
          this.isLoding = false;
          return;
        }
        this.handleUpdatePlan();
        this.bindCane();
      } else {
        this.$confirm("请选择要配课的学生", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      }
    },
    // 提交配课
    handleUpdatePlan() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        stuIds: this.$store.state.fruitIdsPk.join(","),
      };
      this.api.admin.updatePlan(data).then((res) => {
        if (res.flag) {
          this.$notice({
            type: "success",
            message: "配课成功",
          }).isShow();
          this.$store.dispatch("getSchoolInfo");
          this.getfind();
          this.getStuInfo(this.gradeId);
          this.findGradeInfo(this.gradeId); //获取默认学生.老师,已配课老师数量
          this.isChekShow = false;
          this.isLoding = false;
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
          this.isLoding = false;
        }
      });
    },
    // 非公益配课 取消操作
    handleConfigCancle() {
      this.isCheck = false;
      this.isLoding = false;
      // this.$store.state.fruitIdsPk = [];
    },
    // 单选学员
    checkedOne(fruitId) {
      console.log(fruitId);
      if (this.isChekShow) {
        let idIndex = this.$store.state.fruitIdsPk.indexOf(fruitId);
        if (idIndex >= 0) {
          // 如果已经包含了该id, 则去除(单选按钮由选中变为非选中状态)
          this.$store.state.fruitIdsPk.splice(idIndex, 1);
        } else {
          // 选中该checkbox
          this.$store.state.fruitIdsPk.push(fruitId);
          if (this.$store.state.fruitIdsPk.length == this.stuData.length) {
            this.isCheckedAll = true;
          } else {
            this.isCheckedAll = false;
          }
        }
      }
    },
    // 全选学员
    checkedAll() {
      this.isCheckedAll = !this.isCheckedAll;
      // if (this.isCheckedAll) {
      //   // 全选时
      //   this.stuData.forEach(function (fruit) {
      //     this.$store.state.fruitIdsPk.push(fruit.id);
      //   }, this);
      // }
      this.stuData.forEach(function (fruit) {
        if (fruit.planStatus != 1) {
          this.$store.state.fruitIdsPk.push(fruit.id);
        }
      }, this);
    },
    bindSeach() {
      if (!this.isHideSec) {
        this.isHideSec = true;
      } else {
        this.isHideSec = false;
      }
    },
    // 点击查询按钮 搜索全部学生
    changSeachVal() {
      console.log(this.seachVal);
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        gradeId: this.gradeId,
        name: this.seachVal,
      };
      this.api.school.findStudentByGradeId(data).then((res) => {
        this.stuData = res.data;
        this.updateParams.total = res.data.studentPageInfo.total;
        this.planStudents = res.data.planCount;
        this.studentCount = res.data.studentCount;
        this.teacherCount = res.data.teacherCount;
      });
    },
    deleteFruits() {},
  },
  components: {
    comPagination,
  },
};
</script>
<style lang="scss" scoped>
.class {
  background: #fff;
  .class-title {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ba3037;
    margin-bottom: 18px;
  }
}

.class-content {
  display: flex;
  justify-content: space-between;
  .class-content-left {
    width: 245px;
    box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
    border-radius: 2px;
    .class-left-head {
      width: 245px;
      height: 64px;
      background: #ba3037;
      border-radius: 4px 4px 0px 0px;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
      line-height: 64px;
      i {
        margin-right: 10px;
      }
    }
    .children-item-l,
    /deep/ .el-tree-node__content {
      display: flex;
      width: 245px;
      height: 48px;
      justify-content: flex-end;
      align-items: center;
      border-top: 1px solid #f1f1f1;
      padding-left: 0 !important;
      font-weight: bold;
    }
    /deep/ .el-tree-node__label {
      margin-right: 20px;
      font-size: 18px;
      color: #33333d;
    }
    /deep/ .el-tree-node__children .el-tree-node__label {
      font-size: 18px;
      font-family: Adobe Heiti Std;
      color: #33333d;
      font-weight: normal;
    }

    .tree-node-content {
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #33333d;
    }
  }

  .class-content-right {
    width: 1086px;
    box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
    border-radius: 2px;
    .class-right-head-input {
      width: 100%;
      height: 56px;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
    .input-fen-left {
      width: 140px;
      /deep/ .el-input__inner::-webkit-input-placeholder {
        color: #888;
        font-size: 18px;
      }
      /deep/ .el-input__inner::-moz-input-placeholder {
        color: #888;
        font-size: 18px;
      }
      /deep/ .el-input__inner::-ms-input-placeholder {
        color: #888;
        font-size: 18px;
      }
    }
    .class-right-head-input-fen {
      display: flex;
      height: 38px;
      align-items: center;
      justify-content: space-between;
      position: relative;
      span {
        color: #ba3037;
        cursor: pointer;
        display: block;
        background: url(../../../assets/img/school/icon_sec.png) no-repeat 100%
          100%;
        width: 50px;
        height: 56px;
        background-size: cover;
      }
      span:hover {
        background: url(../../../assets/img/school/iocn_sec_active.png)
          no-repeat 100% 100%;
        background-size: cover;
      }
    }
    .input-fen-right {
      width: 300px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #ba3037;
      border-radius: 4px;
      padding: 0 20px;
      font-size: 18px;
      margin-right: -40px;
      input {
        border: none;
        color: #ba3037;
      }
      input::-webkit-input-placeholder {
        color: #888;
      }
      input::-moz-input-placeholder {
        color: #888;
      }
      input::-ms-input-placeholder {
        color: #888;
      }
      span {
        border-left: 1px solid #ba3037;
        padding-left: 20px;
        color: #ba3037;
      }
    }
    .class-right-head-input-btn {
      width: 184px;
      height: 56px;
      background: url(../../../assets/img/school/Btn_Bg_PK.png) no-repeat;
      background-size: cover;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
      line-height: 56px;
      cursor: pointer;
      margin-right: 20px;
    }
    .class-right-head-input-btn:hover {
      background: url(../../../assets/img/school/Btn_Bg_PK_H.png) no-repeat;
      background-size: cover;
    }
    .class-right-int {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #888888;
      padding-bottom: 20px;
      border-bottom: 1px solid #ba3037;
      padding: 20px 30px;
      .class-right-int-left {
        display: flex;
        align-items: center;
      }
      .class-right-int-right {
        display: flex;
        align-items: center;
      }
      p {
        margin-right: 40px;
        span {
          color: #333;
        }
      }
    }
  }
}
.class-stu {
  padding: 0 30px;
  .class-stu-chex {
    display: flex;
    align-items: center;
    margin-top: 12px;
    h2 {
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #333333;
    }
    .stu-chex-btn {
      display: block;
      width: 72px;
      height: 42px;
      background: url(../../../assets/img/school/Btn_alls.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      cursor: pointer;
      img {
        margin-right: 5px;
      }
    }
  }
  .class-sut-con-null {
    margin-top: 5px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 100px;
        text-align: center;
        img {
          width: 42px;
          height: 42px;
        }
        p {
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #888888;
          margin-top: 11px;
        }
      }
    }
  }
  .class-sut-con {
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 148px;
        height: 60px;
        margin-right: 21px;
        position: relative;
        .class-sut-con-item {
          font-size: 14px;
          height: 41px;
          display: flex;
          .stu-info {
            width: 100px;
            padding-top: 10px;
            padding-left: 10px;
          }
          .stu-photo {
            width: 36px;
            height: 36px;
            margin-top: 12px;
            margin-left: 9.5px;
            border-radius: 36px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 36px;
            }
          }
          .stu-name {
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .stu-class {
            color: #888888;
          }
        }
        input[type="checkbox"] {
          position: absolute;
          top: 6px;
          left: 5px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: none;
          zoom: 1;
          outline: none;
          -webkit-appearance: none; /*去除默认样式*/
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          -ms-appearance: none;
          background: url("../../../assets/img/online/icon_UnC.png") no-repeat
            center center;
        }
        input[type="checkbox"]:checked {
          background: url("../../../assets/img/online/icon_choose.png")
            no-repeat center center;
        }
      }
      .stu-class-bg {
        background: url(../../../assets/img/school/Icon_Stu_UnPK.png) no-repeat;
        background-size: 100% 100%;
      }
      .activeBg {
        background: url(../../../assets/img/school/Icon_Stu_C.png) no-repeat;
        background-size: 100% 100%;
      }
      .stu-class-null-bg {
        background: url(../../../assets/img/school/Icon_Stu_YPK.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .class-stu-btn {
    display: flex;
    cursor: pointer;
    .class-stu-bind {
      width: 112px;
      height: 56px;
      background: url(../../../assets/img/school/Btn_Bg_Sure.png) no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 56px;
      border: none;
    }
    .class-stu-cane {
      width: 100px;
      height: 36px;
      border: 1px solid #ba3037;
      border-radius: 6px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ba3037;
      text-align: center;
      line-height: 38px;
      margin-top: 8px;
      margin-left: 24px;
    }
  }
}

.class-left-list
  /deep/
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: none;
  border-left: 3px solid #ba3037;
}
.class-left-list
  /deep/
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content
  .el-tree-node__label {
  color: #ba3037 !important;
}
.class-content {
  display: flex;
  justify-content: space-between;
  .class-content-left {
    width: 245px;
    box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
    border-radius: 2px;
    .class-left-head {
      width: 245px;
      height: 64px;
      background: #ba3037;
      border-radius: 4px 4px 0px 0px;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
      line-height: 64px;
      i {
        margin-right: 10px;
      }
    }
    .class-left-list {
      cursor: pointer;
      .menu-item-title {
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: bold;
        color: #33333d;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .menu-item-1-title {
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #33333d;
        border-bottom: 1px solid #e1e1e1;
        position: relative;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .menu-item-1-2-con {
        height: 48px;
        display: flex;
        align-items: center;
        background: #f1f1f1;
        padding: 0 10px;
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #33333d;
        border-bottom: 1px solid #e1e1e1;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .active {
        border-top: 1px solid #ba3037;
        border-bottom: 1px solid #ba3037;
        border-left: 3px solid #ba3037;
      }
      .menu-item-1-title:after {
        content: "";
        width: 6px;
        height: 6px;
        border-left: 1px solid #666;
        border-bottom: 1px solid #666;
        transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
        position: absolute;
        right: 9px;
        top: 20px;
      }
      .activeSan:after {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
}
</style>